<div class="banner spacing">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="banner-content">
          <div class="banner-heading">
            <h1>One-on-One Tutoring</h1>
            <h1>For Every Student</h1>
            <div class="banner-text">
              <p>Get matched with the right tutor.</p>
              <p>A personalized learning experience.</p>
            </div>
          </div>
          <ng-container *ngIf="showFind">
            <app-find-tutor
              [findTutorfunction]="findTutorfunction"
            ></app-find-tutor>
          </ng-container>
          <ng-container *ngIf="showBecome">
            <app-become-tutor
              [becomeTutorfunction]="becomeTutorfunction"
            ></app-become-tutor>
          </ng-container>
          <div class="banner-btn-wrapper">
            <button class="btn find-a-tutor-btn" (click)="findTutorfunction()">
              Find a Tutor
            </button>
            <button class="btn" (click)="becomeTutorfunction()">
              Become a Tutor
            </button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="banner-img">
          <img src="../../../assets/images/banner-img.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
