import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { reviewType } from '../review-slider-block/review.dt';
@Component({
  selector: 'app-reviews-block',
  templateUrl: './reviews-block.component.html',
  styleUrls: ['./reviews-block.component.css'],
})
export class ReviewsBlockComponent implements OnInit {
  // reviews = [
  //   {
  //     id: 1,
  //     name: 'C. Francis',
  //     rating: 4,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //   },
  //   {
  //     id: 2,
  //     name: 'C. Francis',
  //     rating: 3,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //   },
  //   {
  //     id: 3,
  //     name: 'C. Francis',
  //     rating: 2,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //   },
  //   {
  //     id: 4,
  //     name: 'C. Francis',
  //     rating: 4,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //   },
  //   {
  //     id: 5,
  //     name: 'C. Francis',
  //     rating: 5,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //   },
  //   {
  //     id: 6,
  //     name: 'C. Francis',
  //     rating: 1,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //   },
  // ];
  reviews: reviewType[] = [];
  showLoader: boolean = false;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getAllReviews();
  }

  getAllReviews() {
    this.showLoader = true;
    this.http
      .get<any>(environment.API_URL + 'review/by/limit?limit=10')
      .subscribe(
        (data) => {
          this.showLoader = false;
          const newData = data.map((d: any) => {
            return { ...d, ShowRead: false };
          });
          this.reviews = newData;
          // this.reviews = data;
          console.log(data);
        },
        (err) => {
          this.showLoader = false;
          this.toastr.error(err?.message);
        }
      );
  }
  readBtn(review: any) {
    for (let elm of this.reviews) {
      if (elm.id === review) {
        elm.ShowRead = !elm.ShowRead;
      } else {
        elm.ShowRead = false;
      }
    }
  }
}
