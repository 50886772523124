<div class="services-block spacing">
  <div class="container">
    <div class="heading">
      <h1>Our Services</h1>
    </div>
    <div class="row">
      <div class="col-6" *ngFor="let services of servicesData">
        <div class="services-card">
          <div class="services-content">
            <div class="content-heading">
              <h1>{{ services.servicesTitle }}</h1>
              <p>{{ services.servicesText }}</p>
            </div>
            <div class="services-btn-wrapper">
              <a href="#" [routerLink]="[services.btnLink]">
                <button class="btn">{{ services.btnText }}</button>
              </a>
              <button class="btn" [ngClass]="{'find-a-tutor-btn': services.modelBtn === 'Find a Tutor'}" (click)="services.popupBtn()">
                {{ services.modelBtn }}
              </button>
            </div>
          </div>
          <div class="services-img">
            <img src="{{ services.servicesImg }}" alt="services image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showFind">
  <app-find-tutor [findTutorfunction]="findTutorfunction"></app-find-tutor>
</ng-container>
<ng-container *ngIf="showBecome">
  <app-become-tutor
    [becomeTutorfunction]="becomeTutorfunction"
  ></app-become-tutor>
</ng-container>
