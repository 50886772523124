<div class="faq-block spacing">
  <div class="container">
    <div class="heading">
      <h1>Frequently Asked Questions</h1>
    </div>
    <div class="row">
      <div class="col-5">
        <div class="faq-img">
          <img src="../../../assets/images/faq-img.png" alt="faq img" />
        </div>
      </div>
      <div class="col-7">
        <div class="faq-content">
          <ul>
            <li *ngFor="let faq of faqData">
              <div class="faq-btn" (click)="faqBtn(faq.id)">
                <h2>{{ faq.faqQuestion }}</h2>
                <div class="faq-icon">
                  <img
                    src="{{
                      faq.isOpen
                        ? 'assets/images/minus-icon.png'
                        : 'assets/images/plus-icon.png'
                    }}"
                    alt=""
                  />
                </div>

                <div class="faq-panel content-heading" *ngIf="faq.isOpen">
                  <p>{{ faq.faqAnswer }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
