import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor() {}
  showFind: boolean = false;
  showBecome: boolean = false;
  ngOnInit(): void {}
  findTutorfunction = () => {
    this.showFind = !this.showFind;
  };
  becomeTutorfunction = () => {
    this.showBecome = !this.showBecome;
  };
}
