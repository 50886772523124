import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AboutusPageComponent } from './pages/aboutus-page/aboutus-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ReviewPageComponent } from './pages/review-page/review-page.component';
const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'about',
    component: AboutusPageComponent,
  },
  {
    path: 'services',
    component: ServicesPageComponent,
  },
  {
    path: 'review',
    component: ReviewPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
