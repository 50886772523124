import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-approach',
  templateUrl: './our-approach.component.html',
  styleUrls: ['./our-approach.component.css'],
})
export class OurApproachComponent implements OnInit {
  constructor() {}
  approachData = [
    {
      approachIcon: '/assets/images/approach-icon1.png',
      approachTitle: 'Consult',
    },
    {
      approachIcon: '/assets/images/approach-icon2.png',
      approachTitle: 'Match',
    },
    {
      approachIcon: '/assets/images/approach-icon3.png',
      approachTitle: 'Learn',
    },
  ];
  showFind: boolean = false;
  showBecome: boolean = false;
  ngOnInit(): void {}
  findTutorfunction = () => {
    this.showFind = !this.showFind;
  };
  becomeTutorfunction = () => {
    this.showBecome = !this.showBecome;
  };
}
