<header>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <div class="mobile-btn">
          <button (click)="toggleBtn()">
            <img
              src="../../../assets/images/mobile-icon.png"
              alt="mobile btn"
            />
          </button>
        </div>
        <div class="header-log">
          <a href="#" [routerLink]="['/']">
            <img
              src="../../../assets/images/tutor-logo.png"
              alt="header logo"
            />
          </a>
        </div>
      </div>
      <div class="col-9">
        <ul
          class="main-menu"
          [ngClass]="showMenu ? 'menu-show' : ''"
          (click)="toggleBtn()"
        >
          <li [routerLinkActive]="'active'">
            <a href="#" [routerLink]="['/about']">About Us</a>
          </li>
          <li [routerLinkActive]="'active'">
            <a href="#" [routerLink]="['/services']">Services</a>
          </li>
          <li [routerLinkActive]="'active'">
            <a href="#" [routerLink]="['/review']">Reviews</a>
          </li>
          <!-- <li class="btn-list">
            <div class="become-btn-wrapper">
              <button (click)="becomeTutorfunction()" class="btn">
                Become a Tutor
              </button>
            </div>
          </li> -->
          <li class="btn-list">
            <div class="header-btn">
              <button
                (click)="findTutorfunction()"
                class="btn find-a-tutor-btn"
              >
                Find a Tutor
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
<ng-container *ngIf="showFind">
  <app-find-tutor [findTutorfunction]="findTutorfunction"></app-find-tutor>
</ng-container>
<ng-container *ngIf="showBecome">
  <app-become-tutor
    [becomeTutorfunction]="becomeTutorfunction"
  ></app-become-tutor>
</ng-container>
