<div class="become-tutor" appOutside (click)="clicketOutSide($event)">
  <div class="tutor-content">
    <button class="closePopup" (click)="becomeTutorfunction()">
      <img src="../../../assets/images/closeIcon.png" alt="" />
    </button>
    <div class="content-heading">
      <h1>Become a Tutor</h1>
    </div>
    <div class="form-block">
      <form
        #userFormData="ngForm"
        (submit)="submitFormData(userFormData.value)"
      >
        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">First Name</label>
            <input
              type="text"
              ngModel
              name="firstName"
              placeholder="First Name"
            />
          </div>
          <div class="form-group">
            <label class="required">Last name</label>
            <input
              type="text"
              ngModel
              name="lastName"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">What Subjects Do You Teach?</label>
            <div class="col-lg-12">
              <ng-multiselect-dropdown
                [placeholder]="'Select'"
                [settings]="dropdownSettingsForSubjects"
                [data]="subjectsList"
                [(ngModel)]="selectedSubjects"
                (onSelect)="onSubjectSelect($event)"
                (onSelectAll)="onSelectAllSubjects($event)"
                (onDeSelectAll)="onSelectAllSubjects($event)"
                (onDeSelect)="onSubjectsDeselect($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="form-group">
            <label class="required">What is your current grade level?</label>
            <div class="col-lg-12">
              <ng-multiselect-dropdown
                [placeholder]="'Select'"
                [settings]="dropdownSettingsForGrades"
                [data]="gradesList"
                [(ngModel)]="selectedGrades"
                (onSelect)="onGradesSelect($event)"
                (onDeSelect)="onGradesDeselect($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">Current School</label>
            <input
              type="text"
              ngModel
              name="school"
              placeholder="Current School"
            />
          </div>
          <div class="form-group">
            <label class="required">What days of the week do you prefer?</label>
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForDays"
              [data]="dayList"
              [(ngModel)]="selectedDays"
              (onSelect)="onDaySelect($event)"
              (onSelectAll)="onSelectAllDays($event)"
              (onDeSelectAll)="onSelectAllDays($event)"
              (onDeSelect)="onDaysDeselect($event)"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">What times of the day do you prefer?</label>
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForTimes"
              [data]="timeSlots"
              [(ngModel)]="selectedTimes"
              (onSelect)="onTimeSlotSelect($event)"
              (onSelectAll)="onSelectAllTimeSlots($event)"
              (onDeSelectAll)="onSelectAllTimeSlots($event)"
              (onDeSelect)="onTimeSlotDeselect($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="form-group">
            <label class="required">What is your timezone?</label>
            <div class="col-lg-12">
              <ng-multiselect-dropdown
                [placeholder]="'Select'"
                [settings]="dropdownSettingsForTimeZone"
                [data]="timeZoneList"
                [(ngModel)]="selectedTimeZone"
                (onSelect)="onTimeZoneSelect($event)"
                (onDeSelect)="onTimeZoneDeselect($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">Email address</label>
            <input
              type="email"
              ngModel
              name="email"
              placeholder="Email Address"
            />
          </div>
          <div class="form-group">
            <label class="required">Phone Number</label>
            <input
              type="tel"
              mask="(000)-000-0000"
              ngModel
              name="phoneNumber"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required"
              >What are your preferred methods of communication?</label
            >
            <div class="check-box-wrapper">
              <div class="checkbox">
                <input
                  type="checkbox"
                  (change)="setPreferCommunicationEmail($event)"
                />
                <label>Email</label>
              </div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  (change)="setPreferCommunicationText($event)"
                />
                <label>Text</label>
              </div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  (change)="setPreferCommunicationCall($event)"
                />
                <label>Call</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Additional Details</label>
            <input
              type="text"
              ngModel
              name="additionalDetails"
              placeholder="Additional details"
            />
          </div>
        </div>
        <div class="form-btn-">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
