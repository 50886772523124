<div
  class="review-form-block"
  appOutside
  (click)="clicketOutSide($event)"
  *ngIf="reviewShow"
>
  <div class="review-content">
    <button class="closePopup" (click)="openReviewForm()">
      <img src="../../../assets/images/closeIcon.png" alt="" />
    </button>
    <div class="content-heading">
      <h1>Write a Review</h1>
    </div>
    <div class="form-block">
      <form
        #userReviewData="ngForm"
        (submit)="submitReviewData(userReviewData.value)"
      >
        <div class="form-group-wrapper">
          <div class="rating-content form-group">
            <h3>Rating Your Experience</h3>
            <div class="rating">
              <input
                type="radio"
                ngModel
                name="rating"
                value="5"
                id="5"
              /><label for="5">☆</label>
              <input
                type="radio"
                ngModel
                name="rating"
                value="4"
                id="4"
              /><label for="4">☆</label>
              <input
                type="radio"
                ngModel
                name="rating"
                value="3"
                id="3"
              /><label for="3">☆</label>
              <input
                type="radio"
                ngModel
                name="rating"
                value="2"
                id="2"
              /><label for="2">☆</label>
              <input
                type="radio"
                ngModel
                name="rating"
                value="1"
                id="1"
              /><label for="1">☆</label>
            </div>
          </div>
          <div class="form-group">
            <label>Name :</label>
            <input type="text" ngModel name="name" placeholder="name" />
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label>Service Used</label>
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForServices"
              [data]="servicesList"
              [(ngModel)]="selectedServices"
              (onSelect)="onServicesSelect($event)"
              (onDeSelect)="onServiceDeselect($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="form-group">
            <label>Grade Level</label>
            <div class="col-lg-12">
              <ng-multiselect-dropdown
                [placeholder]="'Select'"
                [settings]="dropdownSettingsForGrades"
                [data]="gradesList"
                [(ngModel)]="selectedGrades"
                (onSelect)="onGradesSelect($event)"
                (onDeSelect)="onGradesDeselect($event)"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>

        <div class="form-group-wrapper custom-group">
          <div class="form-group">
            <label>Review :</label>
            <textarea ngModel name="review" cols="30" rows="10"></textarea>
          </div>
          <!-- <div class="form-group">
            <label>Email(Optional) :</label>
            <input
              type="email"
              ngModel
              name="email"
              placeholder="john@gmail.com"
            />
          </div> -->
        </div>
        <div class="form-btn-">
          <button type="submit">Submit your review</button>
        </div>
      </form>
    </div>
  </div>
</div>
