import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourplaning',
  templateUrl: './ourplaning.component.html',
  styleUrls: ['./ourplaning.component.css'],
})
export class OurplaningComponent implements OnInit {
  constructor() {}
  planDATA = [
    {
      planImg: '/assets/images/plan-img.png',
      planHeading: 'Subject Tutoring',
      planText:
        'We can help students of all grade levels and university students in topics ranging from Math, Computer Science, English, Sciences, and more! ',
    },
    {
      planImg: '/assets/images/plan-img2.png',
      planHeading: 'Test Prep',
      planText:
        'Personalized guidance for the SAT, ACT, and PSAT. We offer several practice exams and build a plan to get you to your dream score.',
    },
    {
      planImg: '/assets/images/plan-img3.png',
      planHeading: 'College Planning',
      planText:
        'From start to finish, we can help build your college list, brainstorm and build your personal statements and supplements, and anything in between.',
    },
  ];
  ngOnInit(): void {}
}
