import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  constructor() {}
  faqData = [
    {
      id: 1,
      isOpen: false,
      faqQuestion: 'Where do the sessions take place?',
      faqAnswer:
        'All of our sessions will be held online via Zoom or Google Meet',
    },
    {
      id: 2,
      isOpen: false,
      faqQuestion: 'What subjects do you offer tutoring in?',
      faqAnswer:
        'We can help with all levels of Math, Sciences, English, and any other academic subject! We also help with Test Preparation for the SAT, PSAT, and ACT as well as work with high schoolers for college counseling, which includes revising college essays, building portfolios, and helping your student get into their top schools!',
    },
    {
      id: 3,
      isOpen: false,
      faqQuestion: 'What is included in the test prep tutoring?',
      faqAnswer:
        'The test prep tutoring will be tailored for the student! If the student is unsure about which test to take, we will begin with an SAT vs. ACT diagnostic exam. Then, we will work towards the students target score through comprehensive concept reviews and practice exams, all of which are included! We offer hourly rates, as well as packages that include all test prep material including the practice exams, prep books, and test-taking strategies! Reach out to learn more!',
    },
    {
      id: 4,
      isOpen: false,
      faqQuestion: 'Do I get to pick the tutor?',
      faqAnswer:
        ' Once you submit the request for the initial consultation through our "Find a Tutor" button, we will reach out and learn about your student and any tutor preferences they may have. From there, we will provide a list of tutors to you to choose from that would be best suited to help your student!',
    },
    {
      id: 5,
      isOpen: false,
      faqQuestion: 'What is the cost for the sessions?',
      faqAnswer:
        'The cost will vary on a number of factors including the type of service, the student’s grades, the length of commitment, among other variables! Call us at (267)-76TUTOR for a free consultation to receive an accurate figure!',
    },
  ];
  ngOnInit(): void {}
  faqBtn(faq: any) {
    for (const element of this.faqData) {
      faq === element.id
        ? (element.isOpen = !element.isOpen)
        : (element.isOpen = element.isOpen = false);
    }
  }
}
