import { reviewType } from './review.dt';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-review-slider-block',
  templateUrl: './review-slider-block.component.html',
  styleUrls: ['./review-slider-block.component.css'],
})
export class ReviewSliderBlockComponent implements OnInit {
  reviews: reviewType[] = [];
  // reviews = [
  //   {
  //     id: 1,
  //     name: 'C. Francis',
  //     ShowRead: false,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //     rating: 4,
  //   },
  //   {
  //     id: 2,
  //     name: 'C. Francis',
  //     ShowRead: false,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals. I also love that the schedule is flexible.',
  //     rating: 4,
  //   },
  //   {
  //     id: 3,
  //     name: 'C. Francis',
  //     ShowRead: false,
  //     review:
  //       'I like that I feel like I am making a difference in someone,s life. My student feels like he is learning and more able to meet his goals.',
  //     rating: 4,
  //   },
  // ];
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getAllReviews();
  }

  getAllReviews() {
    this.http
      .get<reviewType[]>(environment.API_URL + 'review/by/limit?limit=3')
      .subscribe(
        (data) => {
          const newData = data.map((d: reviewType) => {
            return { ...d, ShowRead: false };
          });
          this.reviews = newData;
        },
        (err) => {
          this.toastr.error(err?.message);
        }
      );
  }
  readBtn(review: any) {
    for (let elm of this.reviews) {
      if (elm.id === review) {
        elm.ShowRead = !elm.ShowRead;
      }
    }
  }
}
