import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { BannerComponent } from './components/banner/banner.component';
import { OurplaningComponent } from './components/ourplaning/ourplaning.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { FaqComponent } from './components/faq/faq.component';
import { AboutusPageComponent } from './pages/aboutus-page/aboutus-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { AboutBannerComponent } from './components/about-banner/about-banner.component';
import { SevicesBlockComponent } from './components/sevices-block/sevices-block.component';
import { BecomeTutorComponent } from './components/become-tutor/become-tutor.component';
import { FindTutorComponent } from './components/find-tutor/find-tutor.component';
import { FormsModule } from '@angular/forms';
import { ReviewPageComponent } from './pages/review-page/review-page.component';
import { ReviewBannerComponent } from './components/review-banner/review-banner.component';
import { ReviewsBlockComponent } from './components/reviews-block/reviews-block.component';
import { ReviewFormComponent } from './components/review-form/review-form.component';
import { ReviewSliderBlockComponent } from './components/review-slider-block/review-slider-block.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OutsideDirective } from './outside.directive';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingPageComponent,
    BannerComponent,
    OurplaningComponent,
    OurServicesComponent,
    OurApproachComponent,
    FaqComponent,
    AboutusPageComponent,
    ServicesPageComponent,
    AboutBannerComponent,
    SevicesBlockComponent,
    BecomeTutorComponent,
    FindTutorComponent,
    ReviewPageComponent,
    ReviewBannerComponent,
    ReviewsBlockComponent,
    ReviewFormComponent,
    ReviewSliderBlockComponent,
    OutsideDirective,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot({
      showMaskTyped: true,
      // clearIfNotMatch : true
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
