<div class="find-tutor" appOutside (click)="clicketOutSide($event)">
  <div class="tutor-content">
    <button class="closePopup" (click)="findTutorfunction()">
      <img src="../../../assets/images/closeIcon.png" alt="" />
    </button>
    <div class="content-heading">
      <h1>Find a Tutor</h1>
    </div>
    <div class="form-block">
      <form
        #findFormData="ngForm"
        (submit)="submitStudentData(findFormData.value)"
      >
        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">Student’s First Name</label>
            <input
              type="text"
              ngModel
              name="firstName"
              placeholder="First Name"
            />
          </div>
          <div class="form-group">
            <label class="required">Student’s Last name</label>
            <input
              type="text"
              ngModel
              name="lastName"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required"
              >Which service(s) are you interested in?</label
            >
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForServices"
              [data]="servicesList"
              [(ngModel)]="selectedServices"
              (onSelect)="onServicesSelect($event)"
              (onDeSelect)="onServiceDeselect($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="form-group">
            <label class="required"
              >What subjects is your student interested in?</label
            >
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForSubjects"
              [data]="subjectsList"
              [(ngModel)]="selectedSubjects"
              (onSelect)="onSubjectSelect($event)"
              (onDeSelect)="onSubjectDeselect($event)"
              (onSelectAll)="onSelectAllSubjects($event)"
              (onDeSelectAll)="onSelectAllSubjects($event)"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">What is the student's grade level?</label>
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForGrades"
              [data]="gradesList"
              [(ngModel)]="selectedGrades"
              (onSelect)="onGradesSelect($event)"
              (onDeSelect)="onGradesDeselect($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="form-group">
            <label class="required">What is your timezone?</label>
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForTimeZone"
              [data]="timeZoneList"
              [(ngModel)]="selectedTimeZone"
              (onSelect)="onTimeZoneSelect($event)"
              (onDeSelect)="onTimeZoneDeselect($event)"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required"
              >What days of the week does your student prefer?</label
            >
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForDays"
              [data]="dayList"
              [(ngModel)]="selectedDays"
              (onSelect)="onDaySelect($event)"
              (onDeSelect)="onDayDeSelect($event)"
              (onSelectAll)="onSelectAllDays($event)"
              (onDeSelectAll)="onSelectAllDays($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="form-group">
            <label class="required"
              >What times of the day does your student prefer?</label
            >
            <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettingsForTimes"
              [data]="timeSlots"
              [(ngModel)]="selectedTimes"
              (onSelect)="onTimeSlotSelect($event)"
              (onDeSelect)="onTimeSlotDeselect($event)"
              (onSelectAll)="onSelectAllTimeSlots($event)"
              (onDeSelectAll)="onSelectAllTimeSlots($event)"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>

        <div class="form-group-wrapper">
          <div class="form-group">
            <label class="required">Email address</label>
            <input
              type="email"
              ngModel
              name="email"
              placeholder="Email Address"
            />
          </div>
          <div class="form-group">
            <label class="required">Phone Number</label>
            <input
              type="tel"
              mask="(000)-000-0000"
              ngModel
              name="phoneNumber"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="required"
            >What are your preferred methods of communication?</label
          >
          <div class="check-box-wrapper">
            <div class="checkbox">
              <input
                type="checkbox"
                (change)="setPreferCommunicationEmail($event)"
              />
              <label>Email</label>
            </div>
            <div class="checkbox">
              <input
                type="checkbox"
                (change)="setPreferCommunicationText($event)"
              />
              <label>Text</label>
            </div>
            <div class="checkbox">
              <input
                type="checkbox"
                (change)="setPreferCommunicationCall($event)"
              />
              <label>Call</label>
            </div>
          </div>
        </div>
        <div class="form-btn-">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
