import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-review-banner',
  templateUrl: './review-banner.component.html',
  styleUrls: ['./review-banner.component.css'],
})
export class ReviewBannerComponent implements OnInit {
  @Input() openReviewForm: any = () => {};
  constructor() {}

  ngOnInit(): void {}
}
