import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css'],
})
export class OurServicesComponent implements OnInit {
  constructor() {}
  showFind: boolean = false;
  showBecome: boolean = false;
  becomeTutorfunction = () => {
    this.showBecome = !this.showBecome;
  };
  findTutorfunction = () => {
    this.showFind = !this.showFind;
  };
  servicesData = [
    {
      servicesImg: '/assets/images/services-img1.png',
      servicesTitle: 'Subject Tutoring',
      servicesText:
        'We offer tutoring in all academic levels. Get paired with a tutor to get help preparing for exams, reviewing homework, or just strengthening your concepts!',
      btnText: 'Learn More',
      modelBtn: 'Find a Tutor',
      btnLink: '/services',
      popupBtn: this.findTutorfunction,
    },
    {
      servicesImg: '/assets/images/services-img2.png',
      servicesTitle: 'College Planning',
      servicesText:
        'Work alongside college counselors and successful university students to help plan for the journey ahead and maximum your changes of getting into your dream schools.',
      btnText: 'Learn More',
      modelBtn: 'Find a Tutor',
      btnLink: '/services',
      popupBtn: this.findTutorfunction,
    },
    {
      servicesImg: '/assets/images/services-img3.png',
      servicesTitle: 'Test Prep',
      servicesText:
        'We will help you achieve your target scores in the SAT, ACT, and/or PSAT through practice exams and comprehensive review!',
      btnText: 'Learn More',
      modelBtn: 'Find a Tutor',
      btnLink: '/services',
      popupBtn: this.findTutorfunction,
    },
    {
      servicesImg: '/assets/images/services-img4.png',
      servicesTitle: 'Become a tutor',
      servicesText:
        'We’re always looking to expand our team in all subjects! Please apply if you are interested and in high school or above!',
      btnText: 'Apply',
      modelBtn: 'Become a tutor',
      btnLink: '/',
      popupBtn: this.becomeTutorfunction,
    },
  ];

  ngOnInit(): void {}
}
