<div class="approach-block spacing">
  <div class="container">
    <div class="heading">
      <h1>Our Approach</h1>
      <p>
        We will work with the student and the parent to find the right
        tutor-student tailored for the student’s success. Alongside the student,
        parents, and tutor, a personalized plan is crafted to help the student
        accomplish their academic goals.
      </p>
    </div>
    <div class="row">
      <img
        src="../../../assets/images/approach-line.png"
        class="approach-line"
      />
      <div class="col-4" *ngFor="let approach of approachData">
        <div class="approach-card">
          <div>
            <div class="approach-img">
              <img src="{{ approach.approachIcon }}" alt="approach image" />
            </div>
            <div class="approach-content">
              <div class="content-heading">
                <h1>{{ approach.approachTitle }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-btn-wrapper">
      <button class="btn find-a-tutor-btn" (click)="findTutorfunction()">
        Find a Tutor
      </button>
      <button class="btn" (click)="becomeTutorfunction()">
        Become a Tutor
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf="showFind">
  <app-find-tutor [findTutorfunction]="findTutorfunction"></app-find-tutor>
</ng-container>
<ng-container *ngIf="showBecome">
  <app-become-tutor
    [becomeTutorfunction]="becomeTutorfunction"
  ></app-become-tutor>
</ng-container>
