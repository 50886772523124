<footer>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <div class="footer-logo">
          <img src="../../../assets/images/tutor-logo.png" alt="footer logo" />
        </div>
      </div>
      <div class="col-9">
        <ul class="footer-menu">
          <li>
            <h3>
              <a href="#" [routerLink]="['/about']">About Us</a>
            </h3>
          </li>
          <li>
            <h3>
              <a href="#" [routerLink]="['/services']">Services</a>
            </h3>
          </li>
          <li [routerLinkActive]="'active'">
            <h3>
              <a href="#" [routerLink]="['/review']">Review</a>
            </h3>
          </li>
          <li>
            <h3>
              <button (click)="becomeTutorfunction()" class="btn">
                Become a Tutor
              </button>
            </h3>
          </li>
          <li>
            <h3>
              <button
                (click)="findTutorfunction()"
                class="btn findBtn find-a-tutor-btn"
              >
                Find a Tutor
              </button>
            </h3>
          </li>
          <li>
            <h3>
              <a href="#">Contact Info</a>
            </h3>
            <p>
              <a href="tel:(267)-76TUTOR">(267)-76TUTOR</a>
            </p>
            <!-- <p>
              <a href="mailto:info@tutoringrocks.com">info@tutoringrocks.com</a>
            </p> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<ng-container *ngIf="showFind">
  <app-find-tutor [findTutorfunction]="findTutorfunction"></app-find-tutor>
</ng-container>
<ng-container *ngIf="showBecome">
  <app-become-tutor
    [becomeTutorfunction]="becomeTutorfunction"
  ></app-become-tutor>
</ng-container>
