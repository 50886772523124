import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-become-tutor',
  templateUrl: './become-tutor.component.html',
  styleUrls: ['./become-tutor.component.css'],
})
export class BecomeTutorComponent implements OnInit {
  subjects = [
    {
      id: 1,
      subjectName: 'Math',
    },
    {
      id: 2,
      subjectName: 'Biology',
    },
    {
      id: 3,
      subjectName: 'Chemistry',
    },
    {
      id: 4,
      subjectName: 'Physics',
    },
    {
      id: 5,
      subjectName: 'English',
    },
    {
      id: 6,
      subjectName: 'Writing',
    },
    {
      id: 7,
      subjectName: 'SAT/ACT Prep',
    },
    {
      id: 8,
      subjectName: 'College Counseling',
    },
  ];
  grades = [
    {
      id: 1,
      gradeName: '9th',
    },
    {
      id: 2,
      gradeName: '10th',
    },
    {
      id: 3,
      gradeName: '11th',
    },
    {
      id: 4,
      gradeName: '12th',
    },
    {
      id: 5,
      gradeName: 'Undergraduate Student',
    },
    {
      id: 6,
      gradeName: 'Graduate Student',
    },
    {
      id: 7,
      gradeName: 'Professional',
    },
  ];
  days = [
    {
      id: 1,
      dayName: 'Monday',
    },
    {
      id: 2,
      dayName: 'Tuesday',
    },
    {
      id: 3,
      dayName: 'Wednesday',
    },
    {
      id: 4,
      dayName: 'Thursday',
    },
    {
      id: 5,
      dayName: 'Friday',
    },
    {
      id: 6,
      dayName: 'Saturday',
    },
    {
      id: 7,
      dayName: 'Sunday',
    },
  ];
  times = [
    {
      id: 1,
      timeName: 'Morning (8 AM-12PM)',
    },
    {
      id: 2,
      timeName: 'Afternoon (12 PM - 5 PM)',
    },
    {
      id: 3,
      timeName: 'Evening (5 PM - 11 PM)',
    },
  ];
  timezone = [
    {
      id: 1,
      timeName: 'PST',
    },
    {
      id: 2,
      timeName: 'MST',
    },
    {
      id: 3,
      timeName: 'CST',
    },
    {
      id: 4,
      timeName: 'EST',
    },
  ];
  constructor(private http: HttpClient, private toastr: ToastrService) {}
  @Input() becomeTutorfunction: any = () => {};
  formData: any = {};
  Communication: string[] = [];
  subjectsList: any[] = [];
  gradesList: any[] = [];
  timeSlots: any[] = [];
  dayList: any[] = [];
  timeZoneList: any[] = [];
  selectedSubjects: any[] = [];
  selectedGrades: string = '';
  selectedDays: any[] = [];
  selectedTimes: any[] = [];
  selectedTimeZone: string = '';
  dropdownSettingsForSubjects = {};
  dropdownSettingsForGrades = {};
  dropdownSettingsForDays = {};
  dropdownSettingsForTimes = {};
  dropdownSettingsForTimeZone = {};

  ngOnInit(): void {
    this.subjectsList = this.subjects;
    this.gradesList = this.grades;
    this.timeSlots = this.times;
    this.dayList = this.days;
    this.timeZoneList = this.timezone;
    this.selectedSubjects = [];
    this.selectedGrades = '';
    this.selectedDays = [];
    this.selectedTimes = [];
    this.selectedTimeZone = '';
    this.dropdownSettingsForSubjects = {
      singleSelection: false,
      idField: 'id',
      textField: 'subjectName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dropdownSettingsForGrades = {
      singleSelection: true,
      idField: 'id',
      textField: 'gradeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dropdownSettingsForDays = {
      singleSelection: false,
      idField: 'id',
      textField: 'dayName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dropdownSettingsForTimes = {
      singleSelection: false,
      idField: 'id',
      textField: 'timeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dropdownSettingsForTimeZone = {
      singleSelection: true,
      idField: 'id',
      textField: 'timeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  onSelectAllSubjects(items: any) {
    this.selectedSubjects =
      items.length > 0 ? items.map((item: any) => item.subjectName) : items;
  }

  onSelectAllDays(items: any) {
    this.selectedDays =
      items.length > 0 ? items.map((item: any) => item.dayName) : items;
  }
  onSelectAllTimeSlots(items: any) {
    this.selectedTimes =
      items.length > 0 ? items.map((item: any) => item.timeName) : items;
  }

  onSubjectSelect(item: any) {
    this.selectedSubjects.push(item.subjectName);
  }
  onSubjectsDeselect(item: any) {
    this.selectedSubjects = this.selectedSubjects.filter(
      (i) => i != item.subjectName
    );
  }
  onGradesSelect(item: any) {
    this.selectedGrades = item.gradeName;
  }
  onGradesDeselect(item: any) {
    this.selectedGrades = '';
  }
  onDaySelect(item: any) {
    this.selectedDays.push(item.dayName);
  }
  onDaysDeselect(item: any) {
    this.selectedDays = this.selectedDays.filter((i) => i != item.dayName);
  }
  onTimeSlotSelect(item: any) {
    this.selectedTimes.push(item.timeName);
  }
  onTimeSlotDeselect(item: any) {
    this.selectedTimes = this.selectedTimes.filter((i) => i != item.timeName);
  }
  onTimeZoneSelect(item: any) {
    this.selectedTimeZone = item.timeName;
  }

  onTimeZoneDeselect(item: any) {
    this.selectedTimeZone = '';
  }

  setPreferCommunicationEmail(e: any) {
    if (e.target.checked) {
      this.Communication.push('email');
    } else {
      this.Communication = this.Communication.filter(
        (i: string) => i != 'email'
      );
    }
  }

  setPreferCommunicationText(e: any) {
    if (e.target.checked) {
      this.Communication.push('text');
    } else {
      this.Communication = this.Communication.filter(
        (i: string) => i != 'text'
      );
    }
  }
  setPreferCommunicationCall(e: any) {
    if (e.target.checked) {
      this.Communication.push('call');
    } else {
      this.Communication = this.Communication.filter(
        (i: string) => i != 'call'
      );
    }
  }
  clicketOutSide(event: any) {
    if (event.target.className === 'become-tutor') {
      this.becomeTutorfunction();
    }
  }
  submitFormData(data: NgForm) {
    this.formData = {
      ...data,
      preferCommunication: this.Communication.join(','),
      subjects: this.selectedSubjects.join(','),
      grade: this.selectedGrades,
      preferDays: this.selectedDays.join(','),
      preferSlots: this.selectedTimes.join(','),
      timeZone: this.selectedTimeZone,
    };
    if (
      this.formData.firstName &&
      this.formData.lastName &&
      this.selectedSubjects.length > 0 &&
      this.selectedGrades &&
      this.formData.school &&
      this.selectedDays.length > 0 &&
      this.selectedTimeZone &&
      this.selectedTimes.length > 0 &&
      this.formData.email &&
      this.formData.preferCommunication &&
      this.formData.additionalDetails
    ) {
      this.http
        .post<any>(environment.API_URL + 'tutor/', this.formData)
        .subscribe(
          (res) => {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              html: `${res.message} <br> Our team member will contact you shortly`,
              timer: 5000,
              confirmButtonColor: '#0f6534',
            });
            this.becomeTutorfunction();
          },
          (err) => {
            this.toastr.error(err?.error?.message);
          }
        );
    } else {
      this.toastr.error('Please Select the Required Field');
    }
  }
}
