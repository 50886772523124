<div class="plan-block spacing">
  <div class="container">
    <div class="row">
      <div class="col-4" *ngFor="let plan of planDATA">
        <div class="plan-card">
          <div class="plan-text-content">
            <div class="plan-img">
              <img src="{{ plan.planImg }}" alt="" />
            </div>
            <div class="plan-heading">
              <h1>{{ plan.planHeading }}</h1>
              <p>
                {{ plan.planText }}
              </p>
            </div>
          </div>
          <div class="plan-btn-wrapper">
            <a href="#" [routerLink]="['/services']">
              <button class="btn">Learn more</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
