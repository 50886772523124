import Swal from 'sweetalert2';
import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.css'],
})
export class ReviewFormComponent implements OnInit {
  constructor(private http: HttpClient, private toastr: ToastrService) {}
  @Input() reviewShow: boolean = false;
  @Input() openReviewForm: any = () => {};
  @Output() successReview = new EventEmitter<string>();
  services = [
    {
      id: 1,
      serviceName: 'Subject Tutoring',
    },
    {
      id: 2,
      serviceName: 'College Planning',
    },
    {
      id: 3,
      serviceName: 'Test Prep',
    },
    {
      id: 4,
      serviceName: 'Become a tutor',
    },
  ];
  grades = [
    {
      id: 1,
      gradeName: '9th',
    },
    {
      id: 2,
      gradeName: '10th',
    },
    {
      id: 3,
      gradeName: '11th',
    },
    {
      id: 4,
      gradeName: '12th',
    },
    {
      id: 5,
      gradeName: 'Undergraduate Student',
    },
    {
      id: 6,
      gradeName: 'Graduate Student',
    },
    {
      id: 7,
      gradeName: 'Professional',
    },
  ];
  reviewData: any = {};
  servicesList: any[] = [];
  gradesList: any[] = [];
  selectedGrades: string = '';
  selectedServices: string = '';
  dropdownSettingsForServices = {};
  dropdownSettingsForGrades = {};

  ngOnInit(): void {
    this.servicesList = this.services;
    this.gradesList = this.grades;
    this.selectedServices = '';
    this.selectedGrades = '';
    this.dropdownSettingsForServices = {
      singleSelection: true,
      idField: 'id',
      textField: 'serviceName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dropdownSettingsForGrades = {
      singleSelection: true,
      idField: 'id',
      textField: 'gradeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }
  onServicesSelect(item: any) {
    this.selectedServices = item.serviceName;
  }
  onServiceDeselect(item: any) {
    this.selectedServices = '';
  }
  onGradesSelect(item: any) {
    this.selectedGrades = item.gradeName;
  }
  onGradesDeselect(item: any) {
    this.selectedGrades = '';
  }
  clicketOutSide(event: any) {
    if (event.target.className === 'review-form-block ng-star-inserted') {
      this.openReviewForm();
    }
  }
  submitReviewData = (data: NgForm) => {
    this.reviewData = {
      ...data,
      services: this.selectedServices,
      grade: this.selectedGrades,
    };
    if (
      this.reviewData.rating &&
      this.reviewData.name &&
      this.selectedServices &&
      this.selectedGrades &&
      this.reviewData.review
    ) {
      this.http
        .post<any>(environment.API_URL + 'review/', this.reviewData)
        .subscribe(
          (res) => {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: res.message,
              timer: 1500,
              confirmButtonColor: '#0f6534',
            });
            this.openReviewForm();
            this.successReview.emit('success');
          },
          (err) => {
            this.toastr.error(err?.error?.message);
          }
        );
    } else {
      this.toastr.error('Please Select the Required Field');
    }
  };
}
