import { Component, OnInit, ViewChild } from '@angular/core';
import { ReviewsBlockComponent } from '../../components/reviews-block/reviews-block.component';

@Component({
  selector: 'app-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.css'],
})
export class ReviewPageComponent implements OnInit {
  showReview: boolean = false;
  @ViewChild(ReviewsBlockComponent) review!: ReviewsBlockComponent;
  constructor() {}
  ngOnInit(): void {}

  getAllReviews(event: any) {
    this.review.getAllReviews();
  }

  openReviewForm = () => {
    this.showReview = !this.showReview;
  };
}
