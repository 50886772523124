import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sevices-block',
  templateUrl: './sevices-block.component.html',
  styleUrls: ['./sevices-block.component.css'],
})
export class SevicesBlockComponent implements OnInit {
  showFind: boolean = false;
  showBecome: boolean = false;
  becomeTutorfunction = () => {
    this.showBecome = !this.showBecome;
  };
  findTutorfunction = () => {
    this.showFind = !this.showFind;
  };
  servicesBlockData = [
    {
      servicesImg: '/assets/images/services-block-img1.png',
      servicesTitle: 'Subject Tutoring',
      servicesText:
        'We can work with students of all grade and university levels in topics including Math, Computer Science, English and Sciences. You will be matched up with a tutor that will have 1:1 sessions with the student to best help them. Thic can include preparing for exams, reviewing homework assignments or strengthening your concepts. We’ve got you covered!',
      btnText: 'Find a Tutor',
      popupBtn: this.findTutorfunction,
    },
    {
      servicesImg: '/assets/images/services-block-img2.png',
      servicesTitle: 'College Planning',
      servicesText:
        'We can help in any or all aspects of your college planning process, from formulating college lists to the essay and supplemental process. You will be paired with a specific college guidance advisor, as well as revceive input from student mentors attending top universities. Students can beginning in the College Planning program prior to 12th grade will also receive guidance on extracurricular activities, intership/research experiences, and other opportunities to strengthen the application',
      btnText: 'Find a Tutor',
      popupBtn: this.findTutorfunction,
    },
    {
      servicesImg: '/assets/images/services-block-img3.png',
      servicesTitle: 'Test Prep',
      servicesText:
        'Our SAT/ACT test prep sessions will include initial consultations and diagnistic exams with the student to determine which test they are suited for. A personalized program will be developed for SAT/ACT/PSAT prep based on the student’s strengths and weaknesses and then improved upon using practice exams, content preparation, and strategy techniques.',
      btnText: 'Find a Tutor',
      popupBtn: this.findTutorfunction,
    },
    {
      servicesImg: '/assets/images/services-block-img4.png',
      servicesTitle: 'Become a tutor',
      servicesText:
        'Please apply if you are interested in becoming a tutor. We are seeking students at a high school level and above that can tutor in any of the following subjects: Math, Sciences, Computer Science, Test Prep, College Counseling. You will be matched with students based upon a mutual fit.',
      btnText: 'Become a tutor',
      popupBtn: this.becomeTutorfunction,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
