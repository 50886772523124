import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../app.component.css', './header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor() {}
  showMenu: boolean = false;

  showFind: boolean = false;
  showBecome: boolean = false;
  ngOnInit(): void {}
  toggleBtn() {
    this.showMenu = !this.showMenu;
  }
  findTutorfunction = () => {
    this.showFind = !this.showFind;
  };
  becomeTutorfunction = () => {
    this.showBecome = !this.showBecome;
  };
}
