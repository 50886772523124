import { BecomeTutorComponent } from './../become-tutor/become-tutor.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  showFind: boolean = false;
  showBecome: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  findTutorfunction = () => {
    this.showFind = !this.showFind;
  };
  becomeTutorfunction = () => {
    this.showBecome = !this.showBecome;
  };
}
