<div
  class="reviews-block"
  [ngClass]="reviews.length == 0 ? 'block-height' : ''"
>
  <div class="container">
    <div class="heading" *ngIf="reviews.length == 0">
      <h1>Reviews Not Found</h1>
    </div>
    <div class="row">
      <div class="col-4" *ngFor="let review of reviews">
        <div class="review-card">
          <p>
            <span *ngIf="review.ShowRead === false"
              >{{ review.review.substring(0, 150)
              }}<span *ngIf="review.review.length > 150"> . . .</span></span
            ><span *ngIf="review.ShowRead === true">{{ review.review }}</span>
            <span
              class="readBtn"
              (click)="readBtn(review.id)"
              *ngIf="review.review.length > 150"
              >Expand
              <img src="../../../assets/images/continue-icon.png" alt=""
            /></span>
          </p>
          <div>
            <h3>{{ review.name }}</h3>
            <div
              class="review-icon"
              *ngFor="let i of [].constructor(review.rating)"
            >
              <img
                src="../../../assets/images/review-icon.png"
                alt="review icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showLoader" class="loader-container">
    <div class="loader">
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <!-- <div class="loader--text"></div> -->
    </div>
  </div>
</div>
